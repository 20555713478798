/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountForm_viewer$ref = any;
export type AccountFormRefetchQueryVariables = {||};
export type AccountFormRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountForm_viewer$ref
  |}
|};
export type AccountFormRefetchQuery = {|
  variables: AccountFormRefetchQueryVariables,
  response: AccountFormRefetchQueryResponse,
|};
*/


/*
query AccountFormRefetchQuery {
  viewer {
    ...AccountForm_viewer
    id
  }
}

fragment AccountForm_viewer on Customer {
  email
  firstname
  lastname
  isPasswordSecure
  subscription {
    status
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountFormRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountForm_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountFormRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPasswordSecure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "468375e41fd184b7fca072c62707f5f4",
    "id": null,
    "metadata": {},
    "name": "AccountFormRefetchQuery",
    "operationKind": "query",
    "text": "query AccountFormRefetchQuery {\n  viewer {\n    ...AccountForm_viewer\n    id\n  }\n}\n\nfragment AccountForm_viewer on Customer {\n  email\n  firstname\n  lastname\n  isPasswordSecure\n  subscription {\n    status\n  }\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'ca57b75869e2c2cfb816a5b93b2a1cf3';

module.exports = node;
