import AddAddressMutation from './AddAddressMutation';
import PasswordResetMutation from './PasswordResetMutation';
import RemoveAddressMutation from './RemoveAddressMutation';
import UpdateAccountEmailMutation from './UpdateAccountEmailMutation';
import UpdateAddressMutation from './UpdateAddressMutation';
import UpdateAccountMutation from './UpdateAccountMutation';
import UpdateAccountResendOtpMutation from "./UpdateAccountResendOtpMutation";
import UpdateAccountVerifyOtpMutation from './UpdateAccountVerifyOtpMutation';
import UpdateLoyaltyOptOutMutation from './UpdateLoyaltyOptOutMutation';
import UpdateSalesAddressMutation from './UpdateSalesAddressMutation';
import UpdateDefaultAddressMutation from './UpdateDefaultAddressMutation';

module.exports = {
  AddAddressMutation,
  PasswordResetMutation,
  RemoveAddressMutation,
  UpdateAccountEmailMutation,
  UpdateAddressMutation,
  UpdateAccountMutation,
  UpdateAccountResendOtpMutation,
  UpdateAccountVerifyOtpMutation,
  UpdateLoyaltyOptOutMutation,
  UpdateSalesAddressMutation,
  UpdateDefaultAddressMutation,
};
