import { graphql } from "react-relay";
import { buildMutation } from "../../../mutation";

const mutation = graphql`
  mutation UpdateAccountResendOtpMutation {
    updateAccountResendOtp {
      result
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
