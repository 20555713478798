import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button, Divider, Form, Input, message, Space, Switch } from 'antd';
import Helmet from '~/components/page/Helmet';
import { confirmPassword } from '~/components/form';

import ChangeEmailModal from './ChangeEmailModal';
import { UpdateAccountMutation } from './mutations';

const { Item: FormItem } = Form;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
    md: { span: 9 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
    md: { span: 15 },
    lg: { span: 14 },
  },
};

class AccountForm extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      subscription: PropTypes.shape({
        status: PropTypes.number,
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      changeEmailModalVisible: false,
      changePassword: false,
      confirmDirty: false,
    };
  }

  handleSubmit = (values) => {
    delete values.confirmNewPassword;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        this.formRef.current.resetFields();
        message.success('Updated');
      }
    };
    UpdateAccountMutation.commit(mutation);
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  showModal = () => {
    this.setState({ changeEmailModalVisible: true});
  }

  render() {
    const { viewer, relay } = this.props;
    const { changeEmailModalVisible } = this.state;

    const requiredCurrPw = get(viewer, "isPasswordSecure", true);

    return (
      <>
        <ChangeEmailModal
          viewer={viewer}
          relay={relay}
          visible={changeEmailModalVisible}
          setVisible={(visible) => this.setState({ changeEmailModalVisible: visible })}
        />

        <Form ref={this.formRef} onFinish={this.handleSubmit}>
          <Helmet title="Personal Details" />
          <h1>Personal Details</h1>
          <FormItem
            {...formItemLayout}
            label="First Name"
            name="firstname"
            rules={[
              { transform: (value) => value.trim() },
              { required: true, message: 'required' },
            ]}
            initialValue={viewer.firstname ? viewer.firstname.trim() : null}
          >
            <Input placeholder="First Name" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Last Name"
            name="lastname"
            rules={[
              { transform: (value) => value.trim() },
              { required: true, message: 'required' },
            ]}
            initialValue={viewer.lastname ? viewer.lastname.trim() : null}
          >
            <Input placeholder="Last Name" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Email"
          >
            <FormItem
              name="email"
              initialValue={viewer.email}
              noStyle
              hidden
            >
              <div />
            </FormItem>

            <Space direction="horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span>{viewer.email}</span>
              <Button onClick={this.showModal}>Edit</Button>
            </Space>
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Subscribe to Newsletter"
            name="subscriptionStatus"
            valuePropName="checked"
            rules={[{ required: true, message: 'required' }]}
            initialValue={get(viewer.subscription, 'status') === 1}
          >
            <Switch />
          </FormItem>

          <Divider />

          <FormItem
            {...formItemLayout}
            label="Change Password"
          >
            <Switch
              onChange={(changePassword) => {
              this.setState({changePassword});
            }}
              checked={this.state.changePassword}
            />
          </FormItem>

          {this.state.changePassword && (
            <div>
              {requiredCurrPw && (
                <div>
                  <FormItem
                    {...formItemLayout}
                    label="Current Password"
                    name="currentPassword"
                    rules={[
                      { required: true, message: 'required' },
                    ]}
                  >
                    <Input.Password placeholder="Current Password" />
                  </FormItem>
                </div>
              )}

              <FormItem
                {...formItemLayout}
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: 'required' },
                  (form) => ({
                    validator: (rule, value) => confirmPassword("confirmNewPassword", {form, rule, value})
                  }),
                ]}
              >
                <Input.Password placeholder="New Password" />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="Confirm New Password"
                name="confirmNewPassword"
                rules={[
                  { required: true, message: 'required' },
                  (form) => ({
                    validator: (rule, value) => confirmPassword("newPassword", {form, rule, value})
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm New Password" onBlur={this.handleConfirmBlur} />
              </FormItem>
            </div>
          )}

          <Button type="primary" htmlType="submit">Save</Button>
        </Form>
      </>
    )
  }
}

export default createRefetchContainer(
  AccountForm,
  {
    viewer: graphql`
      fragment AccountForm_viewer on Customer {
        email
        firstname
        lastname
        isPasswordSecure
        subscription {
          status
        }
      }
    `,
  },
  graphql`
    query AccountFormRefetchQuery {
      viewer {
        ...AccountForm_viewer
      }
    }
  `
);
