import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Link } from 'found';
import Shiitake from 'shiitake';

import { Card } from 'antd';
import {
  getProduct,
  IMAGE_PLACEHOLDER,
  productCardstyles,
  recordGA,
  renderPrice,
  renderInStoreOnly,
  renderAddToCart,
}
from './List';
import { isFreeShipping } from './helper';
import { Coupon } from '../svg';
import { ProductLabel } from './ProductLabel';
import { BonusLabel, RedemptionLabel } from './label';
import { PriceDrop, getOnSaleLabels } from './Price';
import GetByDate from '../priority/GetByDate';

export const OnSaleLabel = (props) => {
  const { style={} } = props;

  const defaultStyle = {
    backgroundColor: '#ffef00',
    display: 'table',
    fontWeight: 900,
    fontSize: '15px',
    padding: '0 3px',
    marginLeft: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={{...defaultStyle, ...style}}>
      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
        ON SALE!
      </div>
    </div>
  );
}

OnSaleLabel.propTypes = {
  style: PropTypes.shape({
  }),
};
OnSaleLabel.defaultProps = {
  style: {},
};

class ProductCard extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      freeShippingAmount: PropTypes.number,
      id: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      urlSlug: PropTypes.string.isRequired,
      mainImage: PropTypes.shape({
        id: PropTypes.string,
        thumbnail: PropTypes.string,
      }),
      sku: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      price: PropTypes.number,
      sellable: PropTypes.bool.isRequired,
      callForPrice: PropTypes.bool.isRequired,
      clearance: PropTypes.bool,
      inStoreOnly: PropTypes.bool,
      onlineOnly: PropTypes.bool,
      nonStock: PropTypes.bool,
      globalFreeShipping: PropTypes.bool,
      labels: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      discountCode: PropTypes.shape({
        code: PropTypes.string,
      }),
      priorityShipping: PropTypes.shape({
        getByDate: PropTypes.string,
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
    }),
    listMode: PropTypes.string,
    position: PropTypes.number,
    urlPrefix: PropTypes.string,
  }

  static defaultProps = {
    listMode: 'default',
    urlPrefix: '/product/',
    position: 0,
    router: null,
  }

  render() {
    const { product, position, listMode, relay, router, viewer, urlPrefix } = this.props;
    const { freeShippingAmount } = viewer;
    const url = `${urlPrefix}${product.urlSlug}`;
    const image = get(product, 'mainImage', {});

    // FIXME Image placeholder for products without image
    const styles = get(productCardstyles, listMode, {});

    const redempLabel = product.labels.edges.find(({node}) => node.type === 1);
    const bonusLabels = product.labels.edges.filter(({node}) => node.type === 0);
    const onsaleLabels = getOnSaleLabels(product);

    return (
      <Card key={product.id} className="product-card" bodyStyle={{ padding: '7px', width: '100%', height: '100%' }}>
        <div style={{
          height: '22px',
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
        >
          {isFreeShipping(product, freeShippingAmount) && (
            <div
              style={{
                backgroundColor: '#cb0000',
                color: 'white',
                display: 'inline-block',
                fontSize: '9px',
                fontWeight: '900',
                padding: '4px',
                verticalAlign: 'top',
                whiteSpace: 'nowrap',
              }}
            >
              FREE SHIPPING
            </div>
          )}

          {get(product, 'discountCode.code') && (
            <div
              style={{
                backgroundColor: 'white',
                display: 'inline-block',
                position: 'absolute',
                height: '23px',
                top: '7px',
                right: '7px',
              }}
            >
              <Coupon code={product.discountCode.code} />
            </div>
          )}

          {PriceDrop.hasPriceDrop(product) && <PriceDrop product={getProduct(product)} />}
          {!PriceDrop.hasPriceDrop(product) && onsaleLabels.length > 0 && (<OnSaleLabel />)}
        </div>

        <div className="product-image" style={{position: 'relative'}}>
          <ProductLabel product={product} type="thumbnail" />

          <Link to={url} onClick={() => { recordGA(product, position, { listMode }); }} style={{display: 'block'}}>
            <div className="click-state">
              {image &&
              <img className="img-fluid" title={product.name} alt={product.name} src={image.thumbnail} />
                }
              {!image &&
              <img className="img-fluid" title={product.name} alt={product.name} src={IMAGE_PLACEHOLDER} />
                }
            </div>
          </Link>
        </div>

        <div className="product-info">
          <h2 title={product.name} style={get(styles, 'productName')}>
            <Link className="black-href" to={url} sku={product.sku} onClick={() => { recordGA(product, position, { listMode }); }} >
              <Shiitake lines={2} tagName="span">
                {product.name}
              </Shiitake>
            </Link>
          </h2>

          <RedemptionLabel label={redempLabel} />

          <BonusLabel labels={bonusLabels} />

          <div style={{height: '24px'}}>
            <GetByDate
              date={get(product, 'priorityShipping.getByDate')}
            />
          </div>

          <div style={{position: 'relative'}}>
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              {product.callForPrice && <div style={{marginBottom: '29px'}} className="price">Call For Price</div>}
              {renderPrice(getProduct(product))}
            </div>
            <div style={get(styles, 'cartWrapper', {})}>
              {product.onlineOnly && (
                <span style={{fontSize: '11px', color: '#cb0000', marginRight: '2px', display: 'inline-block', lineHeight: '1', width: '75px', verticalAlign: 'middle' }}>
                  Not available for Click &amp; Collect
                </span>
              )}
              {product.inStoreOnly ? renderInStoreOnly() : renderAddToCart(product, listMode, { relay, viewer, router })}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default createFragmentContainer(ProductCard, {
  viewer: graphql`
    fragment ProductCard_viewer on Customer {
      id
      freeShippingAmount
    }
  `,
  product: graphql`
    fragment ProductCard_product on Product {
      id
      alerts
      name
      type
      configurables(first: 99) {
        edges {
          node {
            id
            type
            discountCode
            name
            model
            secondModel
            sku
            regularPrice
            price
            priceMatch
            sellable
            nonStock
          }
        }
      }
      model
      secondModel
      regularPrice
      price
      priceDrop
      sku
      urlSlug
      freeShipping
      priorityShipping {
        getByDate
      }
      globalFreeShipping
      preorderDate
      backInStockDate
      nonStock
      onlineOnly
      callForPrice
      inStoreOnly
      sellable
      clearance
      discountCode
      brand {
        id
        name
      }
      loyaltyPoints {
        accruing
        dollars
      }
      bonusProducts(first: 999) {
        edges {
          node {
            productId
            productName
            productUrlSlug
            price
            quantity
            optional
          }
        }
      }
      labels(first: 999) {
        edges {
          node {
            id
            imageUrl
            placement
            type
          }
        }
      }
      mainImage {
        id
        thumbnail
      }
    }
  `,
});
