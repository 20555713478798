import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { debounce, get, isEmpty, isEqual } from 'lodash';
import { InfoCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Affix, Button, Col, Divider, message, Row, Spin } from 'antd';
import MediaQuery from 'react-responsive';
import GA4 from '~/ga4';
import Cookies from 'universal-cookie';

import {
  RemoveCouponCodeMutation,
  ChangeFreebiesCheckoutMutation,
  ChangeItemQtyCheckoutMutation,
  RemoveProductFromCartCheckoutMutation,
  CreateOrderMutation,
  SaveQuoteMutation,
  TyroAuthenticatePayerMutation,
} from './mutations';
import { checkoutEnv, decodeSuburbPostcode, onCheckoutCompleted } from './helper';
import messageApi from './message';
import Offer from './offer';
import Humm from './payment/Humm';
import ApplePay from './payment/ApplePay';
import GooglePay from './payment/GooglePay';
import LatitudeFinancial from './payment/LatitudeFinancial';
import LatitudePay from './payment/LatitudePay';
import { PayPalButton } from './payment/Paypal';
import Zip from './payment/Zip';
import Stripe from './payment/Stripe';
import Till from './payment/Till';
import Tyro from './payment/Tyro';

import LoginForm from './LoginForm';
import ItemList from '../cart/ItemList';

import AddressList from './AddressList';
import AddressView from './AddressView';
import Coupon from './Coupon';
import GuestEmail from './GuestEmail';
import PaymentView from './PaymentView';
import ShippingView from './ShippingView';
import StorePickup from './StorePickup';
import FreebiePopup from '../product/FreebiePopup';
import Recaptcha from './Recaptcha';
import Total from './Total';

import './style.css';

const cookies = new Cookies();

// payments methods are disallow to select store pickup
const banStorePickup = ["direct_deposit", "paypal"];

const optionalKeys = [
  'forklift',
  'street',
  'city',
  'stripeToken',
  'useCredit',
  'useLoyaltyPoints'
];

const requiredKeys = [
  'ccType',
  'postcode',
  'shippingMethod',
  'suburb'
];

export const keys = optionalKeys.concat(requiredKeys);

export const saveQuote = debounce((props) => {
  const { relay: { environment }, form, viewer } = props;
  const values = form.getFieldsValue();

  delete values.ccType;
  delete values.ccNumber;
  delete values.ccOwner;
  delete values.ccMonth;
  delete values.ccYear;
  delete values.ccCvv;
  delete values.signupPassword;
  delete values.signupConfirmPassword;

  const input = {
    form: JSON.stringify(values),
  };

  SaveQuoteMutation.commit({
    environment,
    variables: { input },
    viewer
  });
}, 400);

class CheckoutView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      email: PropTypes.string,
      captcha: PropTypes.bool,
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
      cart: PropTypes.shape({
        subtotal: PropTypes.number,
        grandTotal: PropTypes.number,
        shippingCost: PropTypes.number,
        discount: PropTypes.number,
        lines: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
        discounts: PropTypes.arrayOf(PropTypes.object),
        surcharges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
      variables: PropTypes.shape({}),
    }).isRequired,
    form: PropTypes.shape({
      validateFields: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
      getFieldError: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({}).isRequired,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
    Zip.redirect.call(this, props.match.location);
    Humm.redirect.call(this, props.match.location);
    LatitudeFinancial.redirect.call(this, props.match.location);
    LatitudePay.redirect.call(this, props.match.location);

    const errorMsg = get(props, 'match.location.query.error');

    if (errorMsg) {
      message.error(errorMsg);
      props.router.replace('/checkout');
    }

    const email = get(props, 'viewer.email');
    const cart = get(props, 'viewer.cart', {});
    const checkoutSnapshot = get(cart, 'checkoutSnapshot');

    const { sameAddress, deliveryMethod } = this.getInitState(checkoutSnapshot);

    this.state = {
      isCaptcha: get(props.viewer, 'captcha', false),
      isGuest: email ? false : null,
      dest: {
        street: null,
        suburb: null,
        city: null,
        postcode: null,
      },
      billing: {
        suburb: null,
        postcode: null,
      },
      shipping: {
        suburb: null,
        postcode: null,
      },
      newAddressTabKey: 'shipping',
      sameAddress,
      deliveryMethod,
      success: false,
      loading: false,
      freebiePopup: null,
    };

    checkoutEnv.setFn('handleChangeFreebies', this.handleChangeFreebies);
    checkoutEnv.setFn('handleQtyChange', this.handleQtyChange);
    checkoutEnv.setFn('handleRemove', this.handleRemove);

    if (isEmpty(props.match.location.query)) {
      GA4.beginCheckout(cart);
    }
  }

  componentDidMount() {
    this.autofillAddress(this.props);
  }

  componentDidUpdate(prevProps) {
    const { sameAddress, deliveryMethod } = this.state;
    const { form } = this.props;

    const prevCaptcha = get(prevProps, 'viewer.captcha');
    const currCaptcha = get(this.props, 'viewer.captcha');

    const prevAddresses = get(prevProps, 'viewer.addresses.edges', []);
    const currAddresses = get(this.props, 'viewer.addresses.edges', []);

    const prevLines = get(prevProps, 'viewer.cart.lines.edges', []);
    const currLines = get(this.props, 'viewer.cart.lines.edges', []);

    if (prevCaptcha !== currCaptcha) {
      this.setState({ isCaptcha: currCaptcha });
    }

    if (!isEqual(prevAddresses, currAddresses)) {
      const { shippingAddressId, billingAddressId } = form.getFieldsValue(['shippingAddressId', 'billingAddressId']);

      const { node: shipping } = currAddresses.find(({ node }) => node.id === shippingAddressId) || {};
      const { node: billing } = currAddresses.find(({ node }) => node.id === billingAddressId) || {};

      if (shippingAddressId && shippingAddressId !== 'newAddress' && !shipping) {
        form.setFieldsValue({ shippingAddressId: undefined });
      }

      if (billingAddressId && billingAddressId !== 'newAddress' && !billing) {
        form.setFieldsValue({ billingAddressId: undefined });
      }
    }

    if (deliveryMethod === "storepickup" && !this.canStorePickup()) {
      this.setState({ deliveryMethod: "shipping" });
      if (sameAddress) {
        form.setFieldsValue({ billingAddressId: undefined });
      }
    }

    if (!isEqual(prevLines, currLines)) {
      this.refetch();
    }
  }

  componentWillUnmount() {
    checkoutEnv.setFn('handleChangeFreebies', null);
    checkoutEnv.setFn('handleQtyChange', null);
    checkoutEnv.setFn('handleRemove', null);
  }

  getPreVars = () => {
    const { dest } = this.state;

    const vars = this.props.form.getFieldsValue(keys);

    /**
     ** Hack for apply coupon code mutation.
     ** When its first initialize, `useCredit` and `useLoyaltyPoints` are true
     ** Ref to /checkout/routes.js path /checkout prepareVariables,
     * */
    if (get(vars, 'useCredit') === undefined) {
      vars.useCredit = true;
    }
    if (get(vars, 'useLoyaltyPoints') === undefined) {
      vars.useLoyaltyPoints = true;
    }

    return Object.assign(vars, dest);
  }

  getDest = (dest, address, type) => {
    if (address) {
      const {node: addr} = address;

      if (type === 'billing') {
        this.setState({ billingSelected: address.node });
      } else if (type === 'shipping') {
        this.setState({ shippingSelected: address.node });
      }

      return { street: addr.street, suburb: addr.city, city: addr.region, postcode: addr.postcode };
    }

    return dest;
  }

  getInitState = (checkoutSnapshot) => {
    let sameAddress = true;
    let deliveryMethod = 'shipping';

    const shippingMethod = get(checkoutSnapshot, 'shippingMethod');
    const billingAddressId = get(checkoutSnapshot, 'billingAddressId');

    if (shippingMethod === 'storepickup') {
      deliveryMethod = 'storepickup';
    } else {
      sameAddress = !billingAddressId;
    }

    return { sameAddress, deliveryMethod };
  }

  setCaptcha = (errors) => {
    const captcha = errors.find(error => error.captcha === true);
    if (captcha) {
      this.setState({isCaptcha: true});
    }
  }

  setIsGuest = (isGuest) => this.setState({ isGuest });

  setLoading = (loading) => this.setState({ loading });

  setSameAddress = (sameAddress) => this.setState({ sameAddress });

  shouldDisableInput = () => {
    const { isGuest } = this.state;
    const { viewer } = this.props;
    const email = get(viewer, 'email');

    if ((isGuest === true || isGuest === null) || email !== null) {
      return false;
    }

    return true;
  }

  canStorePickup = () => {
    const { viewer } = this.props;
    const shippingMethods = get(viewer, 'cart.shippingMethods.edges', []);
    return shippingMethods.some(({ node }) => node.code === 'storepickup');
  }

  autofillAddress = (props) => {
    const { viewer, form } = props;
    const checkoutSnapshot = get(viewer, 'cart.checkoutSnapshot');
    const shippingMethod = get(checkoutSnapshot, 'shippingMethod');

    if (checkoutSnapshot) {
      let autofillFields = {
        autofill: {},
        dest: { street: '', suburb: '', city: '', postcode: '' },
        callbackAutofill: {},
      }

      autofillFields = this.autofillShipping(viewer, autofillFields);
      autofillFields = this.autofillBilling(viewer, autofillFields);

      const { autofill, dest, callbackAutofill } = autofillFields;

      form.setFieldsValue(autofill, () => {
        form.setFieldsValue(callbackAutofill);

        this.refetch({ ...dest, shippingMethod } , () => {
          this.autofillShippingMethod(this.props, checkoutSnapshot);
        });
        this.setState({ dest });
      });
    }
  }

  autofillBilling = (viewer, autofillFields) => {
    const addresses = get(viewer, 'addresses.edges', []);
    const email = get(viewer, 'email');
    const checkoutSnapshot = get(viewer, 'cart.checkoutSnapshot');

    const { billingAddressId, billing } = checkoutSnapshot;
    const { autofill, callbackAutofill } = autofillFields;

    const billingAddr = addresses.find(addr => addr.node.id === billingAddressId);

    if (billingAddr || billingAddressId === "newAddress") {
      autofillFields = Object.assign(autofillFields, { autofill: { ...autofill, billingAddressId } });
    }

    if (billingAddressId === "newAddress") {
      const fill = this.toFieldsDecorator(email, callbackAutofill, billing, "billing[]");

      autofillFields = Object.assign(autofillFields, { callbackAutofill: fill });
    }

    return autofillFields;
  }

  autofillShipping = (viewer, autofillFields) => {
    const addresses = get(viewer, 'addresses.edges', []);
    const email = get(viewer, 'email');
    const checkoutSnapshot = get(viewer, 'cart.checkoutSnapshot');

    const { shippingAddressId, shipping, billing, shippingMethod } = checkoutSnapshot;
    const guestEmail = get(shipping, 'email');
    const { autofill, callbackAutofill } = autofillFields;
    let { dest } = autofillFields;

    const shippingAddr = addresses.find(addr => addr.node.id === shippingAddressId);

    if (shippingMethod === "storepickup" && !email && guestEmail && billing) {
      this.toFieldsDecorator(email, callbackAutofill, shipping, "shipping[]");
    }

    if (shippingAddr || shippingAddressId === "newAddress") {
      autofillFields = Object.assign(autofillFields, { autofill: { ...autofill, shippingAddressId } });
    }

    if (shippingAddressId === "newAddress") {
      const fill = this.toFieldsDecorator(email, callbackAutofill, shipping, "shipping[]");
      dest = { street: shipping.street, suburb: shipping.city, city: shipping.region, postcode: shipping.postcode };

      autofillFields = Object.assign(autofillFields, { callbackAutofill: fill, dest });
    } else if (shippingAddressId && shippingAddressId !== "newAddress") {
      dest = this.getDest(dest, shippingAddr, "shipping");

      autofillFields = Object.assign(autofillFields, { dest });
    }

    return autofillFields;
  }

  autofillShippingMethod = (props, checkoutSnapshot) => {
    const { form, viewer } = props;
    const shipping = get(viewer, 'cart.shippingMethods.edges', []);

    const shippingMethod = get(checkoutSnapshot, 'shippingMethod');
    const pickupStoreId = get(checkoutSnapshot, 'pickupStoreId');

    const isContainMethod = shipping.find(({node}) => node.code === shippingMethod);

    if (checkoutSnapshot && isContainMethod) {
      form.setFieldsValue({ shippingMethod }, () => {
        if (shippingMethod === "storepickup") {
          form.setFieldsValue({ pickupStoreId });
        }
      });
    }
  }

  toFieldsDecorator = (email, autofill, addressType, customKey) => {
    let key = [];

    if (email) {
      key = Object.keys(addressType).filter(k => k !== "email");
    } else {
      key = Object.keys(addressType);
    }

    key = key.reduce((obj, k) => {
      obj[`${customKey}${k}`] = addressType[k];
      return obj;
    }, {});

    return Object.assign(autofill, key);
  }

  handleChangeFreebies = (freebieIds) => {
    ChangeFreebiesCheckoutMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          freebieIds,
        },
        ...this.getPreVars(),
      },
      viewer: this.props.viewer,
    });
  }

  handleQtyChange = (line, type) => {
    ChangeItemQtyCheckoutMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: line.id,
          type,
          qty: 1,
        },
        ...this.getPreVars(),
      },
      viewer: this.props.viewer,
      onCompleted: () => {
        if (type === 'dec') {
          GA4.removeFromCart(line);
        } else if (type === 'inc') {
          GA4.addToCart(line);
        }
      },
    });
  }

  handleRemove = (line) => {
    RemoveProductFromCartCheckoutMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: line.id,
        },
        ...this.getPreVars(),
      },
      viewer: this.props.viewer,
      onCompleted: () => {
        GA4.removeFromCart(line, line.quantity);
      },
    });
  }

  handleTyroAuthenticatePayer = (details) => {
    TyroAuthenticatePayerMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          quote_id: get(details, 'order_id'),
          session_id: get(details, 'session_id'),
          transaction_id: get(details, 'transaction_id'),
          extra: Tyro.getExtra(),
        }
      },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const content = get(resp, 'tyroAuthenticatePayer.result.redirect_html');

        if (content) {
          Tyro.injectACSIframe(content);
        } else {
          message.error("There was an error, please try again.", 10);
          this.setState({ loading: false });
        }
      },
      onError: (errors) => {
        message.destroy();
        message.error(errors[0].message);
        this.setState({ loading: false });
      },
    });
  }

  handleAddressChange = (changes) => {
    const shippingMethod = null;
    const dest = Object.assign(this.state.dest, changes.dest);

    this.refetch({ ...dest, shippingMethod });

    this.setState({ dest });

    // reset shipping method on address changes
    this.props.form.setFieldsValue({ shippingMethod });
  }

  handleDelivery = (value) => {
    const { form } = this.props;
    this.setState({ deliveryMethod: value });

    const shippingMethod = value === "storepickup" ? value : undefined;

    let paymentMethod = form.getFieldValue('paymentMethod');
    if (value === "storepickup" && banStorePickup.includes(paymentMethod)) {
      paymentMethod = undefined;
    }

    form.setFieldsValue({ shippingMethod, paymentMethod });
  }

  /* https://github.com/facebook/relay/pull/1868
  /* Since this.context.relay.variables has been removed,
   * previous variables are no longer available and thus it is
   * necessary to make a bag of previous variables to merge
   */
  refetch = (props, cb = null) => {
    props = Object.assign(this.getPreVars(), props);

    this.props.relay.refetch((fragmentVariables) => {
      Object.keys(fragmentVariables).forEach(k => {
        if (!keys.includes(k)) {
          console.error('Please update keys in refetch');
        }
      });
      return props;
    }, null, cb);
  }

  submit = (values, { onCompleted, onError } = {}) => {
    message.loading('Loading...', 0);
    this.setState({ loading: true });

    ['billing', 'shipping'].forEach((type) => {
      if (values[`${type}AddressId`] === 'newAddress') {
        const city = this.state[type].suburb || values[type].city;
        const postcode = this.state[type].postcode || values[type].postcode;

        const decoded = decodeSuburbPostcode({city, postcode});

        values[type].city = decoded.city;
        values[type].postcode = decoded.postcode;
      }
    });

    delete values.signupConfirmPassword;

    const {paymentMethod} = values

    // append browser data for saved cards
    if (paymentMethod.includes('till_3ds_')) {
      values.tillData = {
          token: "",
            cardData: {
              "card_type": "",
              "full_name": "",
              "bin_digits": "",
              "first_six_digits": "",
              "last_four_digits": "",
              "month": "",
              "year": "",
              "fingerprint": ""
            },
        extra: JSON.stringify({
          "browserAcceptHeader": "*/*",
          "browserJavaEnabled": window.navigator.javaEnabled(),
          "browserLanguage": window.navigator.language,
          "browserColorDepth": window.screen.colorDepth.toString(),
          "browserScreenHeight": window.screen.height,
          "browserScreenWidth": window.screen.width,
          "browserTimezone": new Date().getTimezoneOffset(),
          "browserUserAgent": window.navigator.userAgent,
        })
      };
    }

    CreateOrderMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: (resp, errors) => {
        onCheckoutCompleted.call(this, resp, errors, { onCompleted, onError });
      },
      onError: (errors) => {
        message.destroy(); // dimiss loading message

        if (typeof onError === 'function') {
          onError();
        }

        if (this.recaptchaRef.current) {
          this.recaptchaRef.current.reset();
        }

        const stripeNextAction = errors.find(error => error.message === "stripe_next_action");
        const defaultErrorMsg = "Could not process your payment, please try again later";

        if (stripeNextAction) {
          Stripe.handleNextAction(stripeNextAction.details)
          .then(result => {
            if (result.error) {
              const msg = get(result, 'error.message', defaultErrorMsg);
              message.error(msg);
            } else {
              CreateOrderMutation.commit({
                environment: this.props.relay.environment,
                variables: { input: values },
                viewer: this.props.viewer,
                onCompleted: (resp, innerErrors) => {
                  onCheckoutCompleted.call(this, resp, innerErrors, { onCompleted, onError });
                },
                onError: (innerErrors) => {
                  message.destroy(); // dimiss loading message
                  const msg = innerErrors[0].message;

                  // cancelled transaction will trigger this one and we don't want to
                  // display anything for that.
                  if (msg !== 'stripe_next_action') {
                    message.error(msg, 10);
                  }
                }
              });
            }
          })
          .catch(err => {
            console.error(err);
            message.error(defaultErrorMsg);
          }).finally(() => {
            this.setState({ loading: false });
          });
          return;
        }

        const tillRedirect = errors.find(error => error.message === "till_redirect" );

        if (tillRedirect) {
          window.location.href = tillRedirect.details.redirectUrl;
          // window.open(
          //   tillRedirect.details.redirectUrl,
          //   '_blank',
          //   'width=435, height=534'
          // );

          return;
        }

        const tyroRedirect = errors.find(error => error.message === "tyro_redirect");
        if (tyroRedirect) {
          const details = get(tyroRedirect, 'details', {});
          const content = get(details, 'redirect_html');

          if (content) {
            Tyro.processMethodData(content, () => {
              this.handleTyroAuthenticatePayer(details);
            });
          } else {
            message.error("There was an error, please try again.", 10);
            this.setState({ loading: false });
          }
          return;
        }

        const redirect = errors.find(error => error.message === "redirect" );
        const reload = errors.find(error => error.message.includes("Optional Freebies not found in cart"));

        this.setCaptcha(errors);

        if (errors[0].token) {
          cookies.set('auth_token', errors[0].token, { path: '/' });
        }

        if (redirect && redirect.details.redirect_uri) {
          window.location.href = redirect.details.redirect_uri;
          return;
        }

        const humm = errors.find(error => error.message === "humm" );

        if (humm) {
          const form = document.createElement('form');
          const params = humm.details;

          form.method = 'POST';
          form.action = params.u_post_action;

          delete params.u_post_action;

          Object.keys(params)
            .filter(k => k.startsWith('x_'))
            .forEach(k => {
              const hiddenField = document.createElement('input');
              hiddenField.type = 'hidden';
              hiddenField.name = k;
              hiddenField.value = params[k];

              form.appendChild(hiddenField);
            });

          document.body.appendChild(form);
          form.submit();
          return;
        }

        if (reload) {
          this.setState({freebiePopup: true});
        }

        messageApi.error(errors, 10);
        this.setState({ loading: false });

        if (errors[0].token) {
          this.refetch();
        }
      },
    });
  }

  handleSubmit = (e = null, extra = {}) => {
    if (e) {
      e.preventDefault();
    }

    // FIXME
    // Hack to reset payment method if latitude has been selected and grand total falls below $20
    if (this.props.form.getFieldValue('paymentMethod') === 'latitude_pay' && this.props.viewer.cart.grandTotal < 20) {
      this.props.form.setFieldsValue({
        paymentMethod: null
      });
    }

    /*
     * Pressing Enter key when email or password fields is focused will
     * submit the whole form. It is necessary to stop form submission
     * when login form is present
     */
    const checkEmailErr = this.props.form.getFieldError("shipping[]email");

    if (checkEmailErr && checkEmailErr[0].toLowerCase().includes("account with this email already exist")) {
      message.error('Please login with your existing email.');
      return;
    }

    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error("Please check the form");
      }

      const { email: ignore, ...billingErr } = err?.billing || {};

      if (!err) {
        const { shippingMethod } = values;
        const guestEmail = get(values, 'shipping.email');
        const billing = get(values, 'billing');

        // Guest checkout that with shipping method of click and collect, will need to clone the shipping email over to billing
        if (shippingMethod === 'storepickup' && guestEmail && billing) {
          delete values.shipping;
          values.billing.email = guestEmail;
        }

        if (this.state.isCaptcha) {
          if (this.recaptchaRef.current.props.grecaptcha) {
            this.recaptchaRef.current.executeAsync().then(token => {
              const type = get(this.recaptchaRef.current, 'props.size');

              values.grecaptcha = {
                type,
                token
              };
              this.submit(values, extra);
            }).catch(() => {
              message.destroy(); // dissmiss loading message
              this.setState({ loading: false });
              message.error("Recaptcha Failed: please refresh your page and try again.");
            });
          } else {
            message.destroy(); // dissmiss loading message
            this.setState({ loading: false });
            message.error(<div>This checkout cannot be verified. <br /> Please <a href="/contacts">contact us</a> for help.</div>, 10);
          }
        } else {
          this.submit(values, extra);
        }
      } else if (err.shippingAddressId || err.shipping) {
        this.changeAddressTab('shipping');
      } else if (err.billingAddressId || Object.keys(billingErr).length > 0) {
        this.changeAddressTab('billing');
      }
    });
  }

  changeAddressTab = (type = 'billing') => {
    this.setState({ newAddressTabKey: type });
  }

  removeDiscount = (discount) => {
    RemoveCouponCodeMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: discount.id,
        },
        ...this.getPreVars(),
      },
      viewer: this.props.viewer,
    });
  }

  canShowPlaceButton = (form) => {
    const shippingMethod = form.getFieldValue('shippingMethod');
    const paymentMethod = form.getFieldValue('paymentMethod');

    if (shippingMethod === 'storepickup' && form.getFieldValue('paymentMethod') === 'paypal') {
      return true;
    }

    if (paymentMethod && paymentMethod.includes("tyro_")) {
      return false;
    }

    // Show button for methods other than these methods
    if (!['paypal', 'zip', 'stripe', 'till_googlepay', 'till_applepay', 'till_3ds', 'tyro', 'tyro_googlepay'].includes(form.getFieldValue('paymentMethod'))) {
      return true;
    }

    // if it is paypal and payer and payment is set
    if (form.getFieldValue('paymentMethod') === 'paypal' && form.getFieldValue('paypalPayerId') && form.getFieldValue('paypalOrderId')) {
      return true;
    }

    // if it is zip and checkout id is set
    if (form.getFieldValue('paymentMethod') === 'zip' && form.getFieldValue('zipCheckoutId')) {
      return true;
    }

    return false;
  }

  isGooglePay = (form) => (form.getFieldValue('paymentMethod') === 'till_googlepay' || form.getFieldValue('paymentMethod') === 'tyro_googlepay')

  isApplePay = (form) => {
    const paymentMethod = form.getFieldValue('paymentMethod');
    return paymentMethod === 'till_applepay' || paymentMethod === 'tyro_applepay';
  }

  isOptFreebiesInCart = (cart) => get(cart, 'isOptFreebiesInCart', true)

  renderAddressView = (form, viewer) => (
    <AddressView
      form={form}
      sameAddress={this.state.sameAddress}
      setSameAddress={this.setSameAddress}
      changeAddressTab={this.changeAddressTab}
      newAddressTabKey={this.state.newAddressTabKey}
      viewer={viewer}
      refetch={this.refetch}
      onChange={this.handleAddressChange}
      disabled={this.shouldDisableInput()}
    />
  )

  renderOffers = (viewer) => <Offer viewer={viewer} />

  renderDelivery = (form, viewer) => {
    const { deliveryMethod } = this.state;
    const disableStorePickup = !this.canStorePickup();

    const lines = get(viewer, 'cart.lines.edges', []);
    const hasOnlineOnly = lines.some(({ node }) => node.product.onlineOnly);

    const isShipping = deliveryMethod === "shipping";
    const isStorePickup = deliveryMethod === "storepickup";

    return (
      <>
        <GuestEmail form={form} viewer={viewer} setIsGuest={this.setIsGuest} />

        <div>
          <h2>Delivery Details</h2>

          <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ flex: 1 }}>
              <Button
                type={isShipping ? "primary" : "default"}
                style={{ width: '100%' }}
                onClick={() => this.handleDelivery("shipping")}
                size="large"
              >
                Delivery
              </Button>
            </div>

            <div style={{ flex: 1 }}>
              <Button
                type={isStorePickup ? "primary" : "default"}
                style={{ width: '100%' }}
                onClick={() => this.handleDelivery("storepickup")}
                size="large"
                disabled={disableStorePickup}
              >
                Click & Collect
              </Button>
              {hasOnlineOnly && (
                <div>
                  <InfoCircleFilled style={{ marginRight: '8px', color: '#faad14' }} />
                  <span style={{ color: '#cb0000' }}>
                    Some products are not available for Click & Collect
                  </span>
                </div>
              )}
            </div>
          </div>

          {isShipping && (
            <div className="section-border">
              {this.renderAddressView(form, viewer)}
            </div>
          )}

          {isStorePickup && (
            <>
              <div className="section-border">
                <StorePickup form={form} viewer={viewer} />
              </div>

              <div className="section-border">
                <h2>Billing Address</h2>
                <AddressList
                  form={form}
                  viewer={viewer}
                  type="billing"
                  onChange={this.handleAddressChange}
                  disabled={this.shouldDisableInput()}
                />
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  renderShippingView = (form, viewer) => (
    <ShippingView
      form={form}
      viewer={viewer}
      dest={this.state.dest}
      refetch={this.refetch}
    />
  )

  renderPaymentView = (form, viewer) => (
    <>
      <div className="section-border">
        <h2>Coupon or Gift Card</h2>
        <Coupon
          viewer={viewer}
          relay={this.props.relay}
          getPreVars={this.getPreVars}
          setCaptcha={this.setCaptcha}
          setLoading={this.setLoading}
        />
      </div>

      <div className="section-border">
        <PaymentView form={form} viewer={viewer} handleSubmit={this.handleSubmit} refetch={this.refetch} />
      </div>
    </>
  )

  renderPlaceOrderButton = (viewer, form, lines, shippingMethod) => {
    const paymentMethod = form.getFieldValue('paymentMethod');
    // Disable the place order button when its:
    // Validating guest email / no item in the cart / loading (prossing order)
    const disable = (form.isFieldValidating('shipping[]email') || lines.length === 0 || this.state.loading);

    let paypal = get(viewer, 'cart.paymentMethods.edges', []).find(({node}) => node.code === 'paypal');

    if (paypal) {
      paypal = paypal.node;
    }

    const isApplePay = this.isApplePay(form);
    const isGooglePay = this.isGooglePay(form);

    return (
      <div style={{paddingBottom: '15px'}}>
        <Divider />
        {(form.getFieldValue('paymentMethod') === 'till_3ds') &&
          <Button style={{width: '100%'}} disabled={disable} type="primary" size="large" onClick={()=>{ Till.init(form, this.handleSubmit, { setLoading: this.setLoading }); }}>Place Order</Button>
        }

        {(paymentMethod && paymentMethod.includes("tyro_") && !isGooglePay && !isApplePay) &&
          <Button style={{width: '100%'}} disabled={disable} type="primary" size="large" onClick={()=>{ Tyro.Threeds.init(form, { cart: viewer.cart, setLoading: this.setLoading, submit: this.handleSubmit }); }}>Place Order</Button>
        }

        {(form.getFieldValue('paymentMethod') === 'tyro') &&
          <Button style={{width: '100%'}} disabled={disable} type="primary" size="large" onClick={()=>{ Tyro.Threeds.init(form, { setLoading: this.setLoading }); }}>Place Order</Button>
        }

        {(form.getFieldValue('paymentMethod') === 'paypal' && !form.getFieldValue('paypalPayerId') && shippingMethod !== 'storepickup') && (
          <div style={{position: 'relative', zIndex: 1}}>
            <PayPalButton extra={get(paypal, 'extra')} form={form} submit={this.handleSubmit} />
          </div>
        )}

        {(viewer.cart !== null && isGooglePay && !this.state.loading) && (
          <div style={{position: 'relative', zIndex: 1}}>
            <GooglePay cart={viewer.cart} form={form} relay={this.props.relay} submit={this.handleSubmit} />
          </div>
        )}

        {(viewer.cart !== null && isApplePay && !this.state.loading) && (
          <div style={{position: 'relative', zIndex: 1}}>
            <ApplePay cart={viewer.cart} form={form} submit={this.handleSubmit} />
          </div>
        )}

        {(form.getFieldValue('paymentMethod') === 'zip') &&
          <Button style={{width: '100%'}} disabled={disable} type="primary" size="large" onClick={()=>{ Zip.init(form, this.handleSubmit); }}>Place Order</Button>
        }

        {(form.getFieldValue('paymentMethod') === 'stripe' && !this.state.loading) &&
          <Button style={{width: '100%'}} disabled={disable} type="primary" size="large" onClick={()=>{ Stripe.init(form, this.handleSubmit, viewer); }}>Place Order</Button>
        }

        {this.canShowPlaceButton(form) &&
          <Button style={{width: '100%'}} disabled={disable} type="primary" size="large" onClick={(e) => this.handleSubmit(e)}>Place Order</Button>
        }

        {this.state.loading && <Spin size="large" />}
        <p><small>By placing the order. You confirm that you have read understand and accept our <a href="/p/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a> and Privacy Policy.</small></p>
      </div>
    )
  }

  renderCartList = (viewer, form) => (
    <div>
      <ItemList
        form={form}
        showActionButtons={false}
        viewer={viewer}
        handleQtyChange={this.handleQtyChange}
        style={{background: 'transparent', border: '0'}}
        dest={this.state.dest}
      />
      <Divider style={{marginBottom: '0px'}} />
    </div>
  )

  renderOptionalFreebie = (cart) => {
    let showPopup = !this.isOptFreebiesInCart(cart);

    if (this.state.freebiePopup) {
      showPopup = true;
    }

    return (
      <FreebiePopup
        viewer={this.props.viewer}
        cart={this.props.viewer.cart}
        relay={this.props.relay}
        handleChangeFreebies={this.handleChangeFreebies}
        visible={showPopup}
        closable={false}
        onCancel={()=> this.setState({freebiePopup: false})}
      />
    )
  }

  renderForm = ({ viewer, form }) => {
    const cart = get(viewer, 'cart', {});
    const lines = get(cart, 'lines.edges', []);
    const shippingMethod = form.getFieldValue('shippingMethod');

    return (
      <div>
        {this.renderOptionalFreebie(cart)}
        <Row type="flex" justify="space-around" gutter={[10, 10]}>
          <Col xs={24} md={13}>
            <h1>Checkout</h1>

            {viewer.email === null && (
              <LoginForm viewer={viewer} relay={this.props.relay} />
            )}

            <Form>
              {this.renderOffers(viewer)}
              {this.renderDelivery(form, viewer)}
              {this.renderShippingView(form, viewer)}
              {this.renderPaymentView(form, viewer)}
            </Form>
          </Col>

          <Col xs={24} md={9} style={{minWidth: '320px'}}>
            <Form>
              <div style={{backgroundColor: '#f5f5f5', paddingTop: '15px'}}>
                <div>
                  <h3 style={{ textAlign: 'center' }}>Shopping Cart</h3>
                  <Divider style={{ margin: '10px 0px' }} />
                  {this.renderCartList(viewer, form)}
                </div>

                {this.state.isCaptcha && (
                  <Recaptcha
                    recaptchaRef={this.recaptchaRef}
                    size="invisible"
                    badge="bottomleft"
                  />
                )}

                <MediaQuery minWidth={768}>
                  <Affix>
                    <div className="total-affix" style={{padding: '0px 16px'}}>
                      <Total viewer={viewer} removeDiscount={this.removeDiscount} />
                      {this.renderPlaceOrderButton(viewer, form, lines, shippingMethod)}
                    </div>
                  </Affix>
                </MediaQuery>

                <MediaQuery maxWidth={767}>
                  <div style={{padding: '0px 16px'}}>
                    <Total viewer={viewer} removeDiscount={this.removeDiscount} />
                    {this.renderPlaceOrderButton(viewer, form, lines, shippingMethod)}
                  </div>
                </MediaQuery>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { viewer, form } = this.props;

    return (
      <div>
        {this.state.success && (
          <div>
            <h3>Processing your order. . . <Spin size="large" /></h3>
          </div>
        )}
        {!this.state.success && this.renderForm({ viewer, form })}
      </div>
    );
  }
}
export default createRefetchContainer(
  Form.create({
    onFieldsChange(props) {
      saveQuote(props);
    },
  })(CheckoutView), {
    viewer: graphql`
    fragment CheckoutView_viewer on Customer @argumentDefinitions(
      forklift: {type: "Boolean"}
      useCredit: {type: "Boolean"}
      useLoyaltyPoints: {type: "Boolean"}
      street: {type: "String", defaultValue: null}
      suburb: {type: "String", defaultValue: null}
      city: {type: "String", defaultValue: null}
      postcode: {type: "String", defaultValue: null}
      shippingMethod: {type: "String"}
      ccType: {type: "String"}
      stripeToken: {type: "String"}
    ) {
      ...FreebiePopup_viewer
      email
      captcha
      cart {
        id
        checkoutSnapshot
        subtotal
        shippingCost(shippingMethod: $shippingMethod)
        grandTotal
        surcharges(ccType: $ccType, stripeToken: $stripeToken, forklift: $forklift) {
          name
          amount
        }
        discount
        discounts(useCredit: $useCredit, useLoyaltyPoints: $useLoyaltyPoints) {
          id
          name
          amount
          removable
        }
        paymentMethods(first: 20) {
          edges {
            node {
              code
              title
              type
              env
              extra
            }
          }
        }
        shippingMethods(first: 20, street: $street, suburb: $suburb, city: $city, postcode: $postcode) {
          edges {
            node {
              code
              title
              priceFormatted
              forklift
            }
          }
        }
        isOptFreebiesInCart
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              isFreebie
              unitPrice
              quantity
              unitDiscount
              unitSurcharge
              rowTotal
              product {
                id
                sku
                name
                model
                onlineOnly
                bulkyGood
                stockAvailable
                limitedStock
                brand {
                  id
                  name
                }
              }
            }
          }
        }
        ...FreebiePopup_cart
      }
      addresses(first: 999) @connection(key: "AddressBook_addresses") {
        edges {
          node {
            id
            firstname
            lastname
            street
            city
            postcode
            region
            telephone
            company
            fax
            country {
              name
            }
          }
        }
      }
      ...ItemList_viewer
      ...AddressList_viewer
      ...AddressView_viewer
      ...GuestEmail_viewer
      ...Offer_viewer
      ...ShippingView_viewer
      ...StorePickup_viewer
      ...PaymentView_viewer
    }
  `,
  },
  graphql`
  query CheckoutViewRefetchQuery(
    $forklift: Boolean,
    $street: String,
    $suburb: String,
    $city: String,
    $postcode: String,
    $useCredit: Boolean,
    $useLoyaltyPoints: Boolean,
    $shippingMethod: String,
    $ccType: String,
    $stripeToken: String
  ) {
    viewer {
      ...CheckoutView_viewer @arguments(
        forklift: $forklift,
        street: $street,
        suburb: $suburb,
        city: $city,
        postcode: $postcode,
        useCredit: $useCredit,
        useLoyaltyPoints: $useLoyaltyPoints,
        shippingMethod: $shippingMethod,
        ccType: $ccType,
        stripeToken: $stripeToken
      )
    }
  }
`,
);
