import { graphql } from "react-relay";
import { buildMutation } from "../../../mutation";

const mutation = graphql`
  mutation UpdateAccountEmailMutation($input: UpdateAccountEmailInput!) {
    updateAccountEmail(input: $input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
