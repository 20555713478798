import React from "react";
import { get } from "lodash";
import { Button, message } from "antd";

const { error: messageError, ...rest } = message;

const reloadPageMsg = (errorMsg) => (
  <div style={{ display: "inline-grid", justifyItems: "center" }}>
    <span>{errorMsg}</span>
    <span>Please refresh your page and try again.</span>
    <Button
      type="primary"
      style={{ marginTop: "5px" }}
      onClick={() => window.location.reload()}
    >
      Refresh
    </Button>
  </div>
);

/**
 * A wrapper function based on antd `message.error`.
 *
 * The content, is now extended to accept `errors`,
 * the results returned from the `onError` callback function from any relay Mutation.
 *
 * @param {string|React.ReactElement|Object[]} [content] - The content of the error message.
 * @param {number} [duration] - The duration of the error message in seconds.
 * Defaults to `5` (AntD Default is `3`).
 * If the `content` contain `errorCode` the duration will be Defaults to `10`.
 *
 * @param {function} [onClose] - The function to be executed when the error message is closed.
 */
const error = (content, duration = undefined, onClose = undefined) => {
  const errorCode = get(content, "[0].error_code");
  const errorMsg = get(content, "[0].message");

  if (typeof content === "string") {
    messageError(content, duration ?? 5, onClose);
  } else if (content && React.isValidElement(content)) {
    messageError(content, duration ?? 5, onClose);
  } else if (errorCode === "reload_page_message" && errorMsg) {
    const msg = reloadPageMsg(errorMsg);
    messageError(msg, duration ?? 10, onClose);
  } else if (errorMsg) {
    messageError(errorMsg, duration ?? 5, onClose);
  }
};

const api = {
  error,
  ...rest,
}

export default api;
