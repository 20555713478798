/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAccountResendOtpMutationVariables = {||};
export type UpdateAccountResendOtpMutationResponse = {|
  +updateAccountResendOtp: ?{|
    +result: ?boolean
  |}
|};
export type UpdateAccountResendOtpMutation = {|
  variables: UpdateAccountResendOtpMutationVariables,
  response: UpdateAccountResendOtpMutationResponse,
|};
*/


/*
mutation UpdateAccountResendOtpMutation {
  updateAccountResendOtp {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UpdateAccountResendOtpPayload",
    "kind": "LinkedField",
    "name": "updateAccountResendOtp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountResendOtpMutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpdateAccountResendOtpMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b953a9eea808ebc8273b8c6784dc958d",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountResendOtpMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountResendOtpMutation {\n  updateAccountResendOtp {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '35a6bdd272106f8c4e8acbeca595314f';

module.exports = node;
