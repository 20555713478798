import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'found';
import Helmet from '~/components/page/Helmet';
import { Button, Col, Collapse, Form, Input, message, Row, Select } from 'antd';
import { getTnCPath } from '~/components/cms/routes';
import { scrollToFirstError } from '~/components/form';
import { getSupportNumber } from '~/helper';
import { PostContactUsFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const { COUNTRY } = process.env;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

export const renderFaqs = (faqs) => (
  (faqs.length !== 0) && (
  <div>
    <h2 style={{fontSize: '15px'}}>Frequently Asked Questions</h2>
    <Collapse accordion style={{backgroundColor: 'white', border: 'none'}}>
      {
      faqs.map(({node: faq}) => (
        <Panel header={faq.question} key={faq.id} forceRender>
          <div dangerouslySetInnerHTML={{__html: faq.answer}} />
        </Panel>
      ))
    }
    </Collapse>
  </div>
))

class Contact extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      faqs: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    viewer: null,
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    PostContactUsFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        this.formRef.current.resetFields();
        message.success('Thank you very much.');
      },
    });
  }

  render() {
    const faqs  = get(this.props.viewer.faqs, 'edges', []);

    return (
      <div>
        <Helmet title="Contact Us" />
        <h1>Contact Us</h1>

        {renderFaqs(faqs)}

        <div style={{backgroundColor: '#f5f5f5'}}>

          <Row style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '25px'}}>
            <Col xs={12} sm={6}>
              <Link to={getTnCPath("shipping")} style={{display: 'block', marginTop: '20px'}}>
                <img alt="Shipping" width="90" height="90" src="/static/images/shipping.svg" />
                <div style={{margin: '20px 0'}}>
                  Shipping
                </div>
              </Link>
            </Col>

            <Col xs={12} sm={6}>
              <Link to={getTnCPath("returns-refunds-repairs")} style={{display: 'block', marginTop: '20px'}}>
                <img alt="Returns" width="90" height="90" src="/static/images/returns.svg" />
                <div style={{margin: '20px 0'}}>
                  Returns
                </div>
              </Link>
            </Col>

            <Col xs={12} sm={6}>
              <Link to="/p/product-recalls" style={{display: 'block', marginTop: '20px'}}>
                <img alt="Product Recall" width="90" height="90" src="/static/images/warranty.svg" />
                <div style={{margin: '20px 0'}}>
                  Product Recall
                </div>
              </Link>
            </Col>

            <Col xs={12} sm={6}>
              <Link to="/p/click-collect-terms-conditions" style={{display: 'block', marginTop: '20px'}}>
                <img alt="Click and Collect" width="90" height="90" src="/static/images/clickncollect.svg" />
                <div style={{margin: '20px 0'}}>
                  Click &amp; Collect
                </div>
              </Link>
            </Col>
          </Row>
        </div>

        <Row>
          <Col xs={24} md={12} style={{paddingLeft: '10px', paddingRight: '10px', marginBottom: '15px'}}>
            <div style={{textAlign: 'center', backgroundColor: '#f5f5f5', minHeight: '150px', paddingTop: '10px'}}>
              <div><b>Live Chat</b></div>
              {COUNTRY === 'AU' && (
                <>
                  Monday to Friday <b>8:30am to 4:30pm</b> Sydney Time<br />
                  Excludes: NSW Public Holidays<br />
                </>
              )}
              {COUNTRY === 'NZ' && (
                <>
                  Monday to Friday <b>10:30am to 6:30pm</b><br />
                </>
              )}
              <Button
                type="primary"
                size="large"
                onClick={()=> {
                  const el = document.querySelector('.chat-button a');

                  if (el && el.onclick) {
                    el.onclick();
                  } else if (el && el.click) {
                    el.click();
                  }
                }}
              >
                Live Chat
              </Button>
            </div>
          </Col>

          <Col xs={24} md={12} style={{paddingLeft: '10px', paddingRight: '10px', marginBottom: '15px'}}>
            <div style={{textAlign: 'center', backgroundColor: '#f5f5f5', minHeight: '150px', paddingTop: '10px'}}>
              <div><b>Online Customer Service</b></div>
              {COUNTRY === 'AU' && (
                <>
                  Monday to Friday <b>9:00am to 4:00pm</b> Sydney Time<br />
                  Excludes: NSW Public Holidays<br />
                </>
              )}
              {COUNTRY === 'NZ' && (
                <>
                  Monday to Friday <b>11:00am to 6:00pm</b><br />
                </>
              )}
              <b>{getSupportNumber().value}</b>

              <div>
                <br />
                <b>Store Customer Service</b><br />
                Contact your <Link to="/stores">nearest store</Link> during store operating hours for all enquiries.
              </div>
            </div>
          </Col>
        </Row>

        <section style={{padding: '10px'}}>
          <h2>Email Us</h2>
          <p>If your enquiry is urgent, please contact us via live chat or call us during our opening hours. Our current response time through our contact form is 2 business days.</p>

          <Form ref={this.formRef} onFinish={this.handleSubmit} scrollToFirstError={scrollToFirstError}>
            <FormItem
              {...formItemLayout}
              label="Issue Type"
              name="issueType"
              rules={[
                { required: true, message: 'Required' },
              ]}
            >
              <Select placeholder="Select an issue type">
                <Option value="I am getting an error message">I am getting an error message</Option>
                <Option value="I got something different than I ordered">I got something different than I ordered</Option>
                <Option value="I have a question about your product range">I have a question about your product range</Option>
                <Option value="I have a question about your returns process or policy">I have a question about your returns process or policy</Option>
                <Option value="I have a question regarding my account">I have a question regarding my account</Option>
                <Option value="I have placed an order but did not get a confirmation">I have placed an order but did not get a confirmation</Option>
                <Option value="I would like to amend my order">I wouldd like to amend my order</Option>
                <Option value="I would like to follow up on a return I lodged">I would like to follow up on a return I lodged</Option>
                <Option value="I would like to know more about a promotional Newsletter I received">I would like to know more about a promotional Newsletter I received</Option>
                <Option value="I would like to know where my order is">I would like to know where my order is</Option>
                <Option value="I would like to return a faulty product">I would like to return a faulty product</Option>
                <Option value="My inquiry is not listed here">My inquiry is not listed here</Option>
              </Select>
            </FormItem>

            <FormItem
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.issueType !== currentValues.issueType}
            >
              {({ getFieldValue }) => {
                if (getFieldValue('issueType') === 'I have a question about your product range') {
                  return (
                    <FormItem
                      {...formItemLayout}
                      label="Postcode"
                      name="postcode"
                      rules={[
                        { required: true, message: 'Required' },
                      ]}
                    >
                      <Input maxLength="4" />
                    </FormItem>
                  )
                }
                return null;
              }}
            </FormItem>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <FormItem
                      {...formItemLayout}
                      label="First Name"
                      name="firstName"
                      rules={[
                        { required: true, message: 'Required' },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={12}>
                    <FormItem
                      {...formItemLayout}
                      label="Last Name"
                      name="lastName"
                      rules={[
                        { required: true, message: 'Required' },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24}>
                    <FormItem
                      {...formItemLayout}
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: 'Required' },
                        { type: 'email', message: 'Invalid Email' },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={12}>
                    <FormItem
                      {...formItemLayout}
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[
                        { required: true, message: 'Required' },
                      ]}
                    >
                      <Input placeholder="Phone Number" />
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={12}>
                    <FormItem
                      {...formItemLayout}
                      label="Order Number"
                      name="orderNumber"
                    >
                      <Input placeholder="Order Number" />
                    </FormItem>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} md={12}>
                <FormItem
                  {...formItemLayout}
                  label="Message"
                  name="message"
                  rules={[
                    { required: true, message: 'Required' },
                  ]}
                >
                  <TextArea placeholder="Message" rows={6} />
                </FormItem>

                <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                  Submit
                </Button>
              </Col>
            </Row>

          </Form>
        </section>
      </div>
    );
  }
}

export default createFragmentContainer(Contact, {
  viewer: graphql`
    fragment Contact_viewer on Customer {
      id
      faqs(first: 99) {
        edges {
          node {
            id
            question
            answer
          }
        }
      }
    }
  `,
});
