import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ArrowLeftOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Form, Input, message, Modal } from "antd";
import { get } from "lodash";
import { EMAIL_CON_REGEX } from "~/helper";
import {
  UpdateAccountEmailMutation,
  UpdateAccountResendOtpMutation,
  UpdateAccountVerifyOtpMutation,
} from "./mutations";

const { Item: FormItem } = Form;

const OTPCooldown = 15;
const oneSecond = 1000; // ms

const UpdateEmail = (props) => {
  const { viewer, relay, onCompleted, onError } = props;
  const { email } = viewer;

  const [form] = Form.useForm();

  const onFinish = (formValues) => {
    const input = { email: formValues.email };

    const mutation = {
      environment: relay.environment,
      variables: { input },
      viewer,
      onCompleted: () => onCompleted(),
      onError,
    };

    UpdateAccountEmailMutation.commit(mutation);
  };

  return (
    <>
      <p>A <b>security code</b> will be sent to your current email address: <b>{email}</b><br /></p>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          name="email"
          label="New email address"
          rules={[
            {
              type: "email",
              message: "Invalid Email",
              transform: (value) => (value || "").trim(),
            },
            {
              required: false,
              message: "Please double check your email",
              pattern: EMAIL_CON_REGEX,
            },
            { required: true, message: "required" },
          ]}
        >
          <Input type="email" />
        </FormItem>

        <FormItem noStyle>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Request a Code
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

UpdateEmail.title = "Changing email address";

const VerifyOtp = (props) => {
  const { viewer, relay, onCompleted, onError } = props;

  const [form] = Form.useForm();
  const [cooldown, setCooldown] = useState(OTPCooldown);

  useEffect(() => {
    const interval = setInterval(
      () => setCooldown((s) => (s === 0 ? s : s - 1)),
      oneSecond
    );

    return () => clearInterval(interval);
  }, []);

  const onFinish = (formValues) => {
    const { otp } = formValues;

    const mutation = {
      environment: relay.environment,
      variables: { input: { otp: `${otp}` } },
      viewer,
      onCompleted,
      onError,
    };

    UpdateAccountVerifyOtpMutation.commit(mutation);
  };

  const onResendOTP = () => {
    const mutation = {
      environment: relay.environment,
      variables: {},
      viewer,
      onCompleted: () => {
        message.success("Please check your email for the Security Code.", 10);
        setCooldown(OTPCooldown);
      },
      onError,
    };

    UpdateAccountResendOtpMutation.commit(mutation);
  };

  return (
    <>
      <p>Please enter the security code sent to your email address <b>{viewer.email}</b></p>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          name="otp"
          label="Enter Security Code"
          rules={[{ required: true, message: "required" }]}
        >
          <Input className="otp-input" type="number" inputMode="numeric" />
        </FormItem>

        <FormItem style={{ marginBottom: "10px" }}>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Verify
          </Button>
        </FormItem>

        <FormItem
          style={{ marginBottom: "10px", textAlign: "center" }}
          help={
            cooldown > 0 ? (
              <>
                <ExclamationCircleFilled style={{ color: "#faad14" }} />
                <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                  Please wait {cooldown} seconds before requesting another code.
                </span>
              </>
            ) : null
          }
        >
          <Button type="link" onClick={onResendOTP} disabled={cooldown > 0}>
            Resend Security Code
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

VerifyOtp.title = "Verify your email address";

const STEPS = {
  UPDATE: "update",
  VERIFY: "verify",
};

const ChangeEmailModal = (props) => {
  const { relay, visible, setVisible } = props;

  const [step, setStep] = useState(STEPS.UPDATE);

  const handleCancel = () => {
    setVisible(false);
  };

  const getTitle = () => {
    const style = { display: "inline-flex", alignItems: "center" };

    if (step === STEPS.UPDATE) {
      return (
        <div style={style}>
          <div
            style={{ display: "inline-block", width: "32px", height: "32px" }}
          >
            &nbsp;
          </div>
          <span style={{ marginLeft: "10px" }}>{UpdateEmail.title}</span>
        </div>
      );
    } else if (step === STEPS.VERIFY) {
      return (
        <div style={style}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            style={{ color: "rgba(0, 0, 0, 0.45)" }}
            onClick={() => setStep(STEPS.UPDATE)}
          />
          <span style={{ marginLeft: "10px" }}>{VerifyOtp.title}</span>
        </div>
      );
    }

    return null;
  };

  const onUpdateEmailComplete = () => {
    setStep(STEPS.VERIFY);
  };

  const onUpdateEmailError = (errors) => {
    const errorMsg = get(errors, "[0].message");

    if (errorMsg) {
      message.error(errorMsg);
    }
  };

  const onVerifyOtpComplete = () => {
    message.success("Your email has been updated.");

    relay.refetch({});
    setVisible(false);
    setStep(STEPS.UPDATE);
  };

  const onVerifyOtpError = (errors) => {
    const errorMsg = get(errors, "[0].message") ?? "";
    const errorCode = get(errors, "[0].error_code");

    const taken = errorMsg.includes("email has already been taken");

    if (taken) {
      message.error("Account with this email address already exist.");
    } else if (errorMsg) {
      message.error(errorMsg);
    }

    if (taken || errorCode === "too_many_attempts" || errorCode === "expired") {
      setStep(STEPS.UPDATE);
    } else if (errorCode === "not_authorized") {
      setVisible(false);
      setStep(STEPS.UPDATE);
    }
  };

  return (
    <>
      <Modal
        title={getTitle()}
        visible={visible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        {step === STEPS.UPDATE && (
          <UpdateEmail
            {...props}
            onCompleted={onUpdateEmailComplete}
            onError={onUpdateEmailError}
          />
        )}
        {step === STEPS.VERIFY && (
          <VerifyOtp
            {...props}
            onCompleted={onVerifyOtpComplete}
            onError={onVerifyOtpError}
          />
        )}
      </Modal>
    </>
  );
};

const propTypes = {
  viewer: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
    refetch: PropTypes.func.isRequired,
  }).isRequired,
};

UpdateEmail.propTypes = {
  ...propTypes,
  onCompleted: PropTypes.func.isRequired,
};

VerifyOtp.propTypes = {
  ...propTypes,
  onCompleted: PropTypes.func.isRequired,
};

ChangeEmailModal.propTypes = {
  ...propTypes,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default ChangeEmailModal;
